<template>
  <div class="d-flex justify-content-center">
    <div class="col-12 col-lg-6 col-md-7 col-xl-5">
      <a-card>
        <ChangePasswordForm />
      </a-card>
    </div>
  </div>
</template>

<script>
import ChangePasswordForm from '@/components/ChangePassword'

export default {
  components: { ChangePasswordForm },
}
</script>
